import {Outlet} from 'react-router-dom';
import BigSidebar from './../components/Navigation/BigSideBar'
import Wrapper from './../assets/Wrappers/RootLayout'
import Navbar from '../components/Navigation/Navbar'
import {useSelector, useDispatch} from 'react-redux'
import SmallSidebar from './../components/Navigation/SmallSidebar'
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { uiActions } from '../store/ui-slice';

let Root=()=>{

  let dispatch=useDispatch()
  let navigate=useNavigate()

  let showSideBar=useSelector(state=>state.ui.showSideBar)

  // console.log('---------- show side bar ++++',showSideBar)

  useEffect(() => {
    let token=localStorage.getItem('token');
    let user=localStorage.getItem('user');

    if(token){
      let expireIn=localStorage.getItem('expireIn')
    let time=new Date().getTime()
    // console.log('+++++ current time',time)
    // console.log('+++++ expire time time',expireIn)
    if(time<expireIn){
      // console.log('+++++++++ if working')
    dispatch(uiActions.loggedIn({token:token,user:user,isLoggedIn:true}))
    return ;
    }else{
      navigate('/login')
    }
    }else{
      // console.log('+++ else working')
      navigate('/login')
    }


  }, [])

    return (
        <>
          <Wrapper>
            <main className='dashboard'>
              <SmallSidebar showSideBar={showSideBar}/>
              <BigSidebar showSideBar={showSideBar}/>
              <div>
                <Navbar />
                <div className='dashboard-page'>
                  <Outlet />
                </div>
              </div>
            </main>
          </Wrapper>
        </>
      )
}


export default Root
